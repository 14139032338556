// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// This is necessary to work stand-alone, but causes problems when embedded in the main site.
// We need to work out how to handle that during development, but for now it's commented out.
// import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import TextHighlight from 'vue-text-highlight'
import '../node_modules/vis-timeline/styles/vis-timeline-graph2d.min.css'
import './assets/css/ccike.css'

import App from './App'
import Vue from 'vue'
import router from './router'

import BootstrapVue from 'bootstrap-vue'
import browserDetect from 'vue-browser-detect-plugin'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faArrowUpShortWide, faArrowDownShortWide, faMinus, faXmark, faWindowClose, faAngleUp, faAngleDown, faMagnifyingGlass, faArrowRight, faArrowLeft, faArrowDownLong, faSignInAlt, faHouse, faFileLines, faDownload, faPlusCircle, faMinusCircle, faCalendar, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faCheck)
library.add(faArrowUpShortWide)
library.add(faArrowDownShortWide)
library.add(faMinus)
library.add(faXmark)
library.add(faWindowClose)
library.add(faAngleUp)
library.add(faAngleDown)
library.add(faMagnifyingGlass)
library.add(faArrowRight)
library.add(faArrowLeft)
library.add(faArrowDownLong)
library.add(faSignInAlt)
library.add(faHouse)
library.add(faFileLines)
library.add(faDownload)
library.add(faPlusCircle)
library.add(faMinusCircle)
library.add(faCalendar)
library.add(faArrowUpRightFromSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('text-highlight', TextHighlight)
Vue.component('open-dropdown-icon', {
  template: '<font-awesome-icon class="" icon="angle-down"/>'
})
Vue.component('close-dropdown-icon', {
  template: '<font-awesome-icon class="" icon="angle-up"/>'
})

Vue.use(BootstrapVue)
Vue.use(browserDetect)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  router,
  template: '<App class="container-fluid row"/>',
  components: {
    App
  },
  mounted () {
    const vm = this

    const currentRoutes = vm.$router.getRoutes().filter(function (route) {
      return route.name === 'Search'
    })
    const standaloneDashboard = (currentRoutes.length === 0)

    if (standaloneDashboard) {
      vm.$router.replace('/')
    }
  }
}).$mount('#app')
