/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

console.log("Creating a router for ODP from pathname: ", document.location.pathname)

const dashboardOnlyMode = 'abstract'
const appMode = 'hash'

const dashboardOptions = [
  { path: '/', name: 'Dashboard', component: 'Dashboard', props: true }
]
const appOptions = [
  { path: '/dashboard', name: 'Dashboard', component: 'Dashboard', props: true },
  { path: '/search', name: 'Search', component: 'Search', props: true },
  { path: '/', name: 'redirectRoot', redirect: { name: 'Dashboard' } },
  { path: '*', name: 'redirectAll', redirect: { name: 'Dashboard' } }
]

let routerOptions = undefined
let mode = undefined

if (document.location.pathname.includes('/data')) {
  mode = appMode
  routerOptions = appOptions
} else {
  mode = dashboardOnlyMode
  routerOptions = dashboardOptions
}

//mode = appMode
//routerOptions = appOptions

const routes = routerOptions.map(route => {
  return {
      ...route,
      component: () => import(`@/components/${route.component}`)
  }
})

Vue.use(VueRouter)

/* eslint-disable no-new */
export default new VueRouter({
  routes,
  mode: mode,
})
