<template>
  <div id="app">
    <router-view class="view"></router-view>
  </div>
</template>

<style>
#app {
  color: #2c3e50;
}
</style>

<script>
/* eslint-disable */
import Vue from "vue";

const vueName = "App";

export default {
  name: vueName,
  data() {
    return {
      /* These are referred to via 'this.<property>
       */
      appCreated: undefined,
    };
  },
  async created() {},
  async mounted() {console.log("In App.vue")},
  methods: {},
};
</script>
